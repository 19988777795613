import React from 'react';
import Form from '../Form/index.js';

function index() {
  return (
    <Form type="signup" />
  )
}

export default index
