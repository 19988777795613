import { useDispatch, useSelector } from "react-redux";
import "../Paradox/index.css";
import MenuIcon from "@mui/icons-material/Menu";
import { showSidebar, hideSidebar } from "../../../../Redux/Actions/index";
import axios from "axios";

const Index = () => {
  //action dispatcher
  const dispatch = useDispatch();

  //track redux state
  const sidebarVisibility = useSelector((state) => state.sidebarVisibility);

  //close or open sidebar
  const changeSidebar = () => {
    console.log(sidebarVisibility);
    if (sidebarVisibility === "hide") {
      // //update the redux store
      dispatch(showSidebar());
    } else {
      //update the redux store
      dispatch(hideSidebar());
    }
  };

  return (
    <div className="container getStarted pt-5">
      <div className="getStartedRow px-4 pt-3">
        <MenuIcon className="btn p-0" onClick={changeSidebar} />
        <br />
        <br />
        <h3 className="bold">Paradox</h3>
        <span>Content</span>
        <p className="p-3 para1">
          This document details what you will experience with Paradox. It will
          cover everything you need to know before getting started with Paradox;
          from the interface, to the features.
        </p>
        <span>Introduction</span>
        <p className="p-3 para1">
          Paradox is a first of its kind software. Paradox lets you use the more
          diagram-like, dataflow inspired structure to represent data
          processing-centric functionality. It is the next generation
          programming tool for everyone. Paradox is easily accessible and easy
          to use. It works in a diagram-like, data flow inspired structure to
          represent data processing-centric functionality. It uses nodes which
          runs codes and Paradox provides a simple framework to create them and
          an editor to use them.The Use cases range almost through the whole
          Python domain, from IoT, over machine learning, generative
          engineering, Raspberry Pi, web APIs, and much more. Anyone with basic
          Python programming language understanding can use Paradox with ease.
        </p>
        <br />
        <span>Background</span>
        <p className="p-3 para1">
          In computing, a process is the instance of a computer program that is
          being executed by one or many threads. It contains the program code
          and its activity. Depending on the operating system (OS), a process
          may be made up of multiple threads of execution instructions
          concurrentl. In Paradox the input-process-output concept is utilized
          on top of dataflow programming. In programming we have sequential flow
          and data flow methods that helps determine the flow of the program. In
          sequential flow the function are performed in steps, an action must be
          triggered for the program to start; it is the traditional way of doing
          pragramming. In dataflow programming application are represented as a
          set of nodes (also called blocks) with input and/or output ports in
          them. It introduces a new programming paradigm that internally
          represents application as a directed graph, similarly to a dataflow
          diagram. Below we have the examples of sequential flow and dataflow
          progamming that can be done in Paradox.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/sequential-flow.jpg"}
        />
        <p>Sequential FLow</p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/data-flow-eg.jpg"}
        />
        <p>Data Flow</p>
        <p className="p-3 para1">
          The key benefit of Dataflow programming is that, in dataflow, more
          than one instruction can be executed at once. Thus, if several
          instructions be- come fire able at the same time, they can be executed
          in parallel. This simple principle provides the potential for massive
          parallel execution at the instruction level. DFP(Data Flow
          Programming) has enabled many visual programming languages that
          provide a more user-friendly interface so that even non-technical
          users can write programs. Such languages are also suited for rapid
          prototyping. Paradox utilizes dataflow programming in Json formating
          so it is easy to use and mostly error free.
        </p>
        <br />
        <h3 className="bold">Features of Paradox</h3>
        <hr />
        <ol type="1" className="p-4 para1">
          <li>Create clear and pretty flows</li>
          <li>Built-In Interpreter</li>
          <li>Python3 by default</li>
          <li>Source Code Access</li>
          <li>Stylus support</li>
          <li>Work fast with parallel computing</li>
          <li>Multiple Themes</li>
          <li>Multiple Modes</li>
          <li>Multiple Extension</li>
        </ol>
        <br />
        <h4 className="bold">1. Create clear and pretty flows</h4>
        <p className="p-3 para1">
          Visual Scripting is a huge part of Paradox. Paradox uses blocks and
          nodes that help in creating clear and pretty flow. It makes it easy to
          follow, easy to script for rookie programmer and experienced
          programmers alike.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/feature-1.jpg"}
        />
        <br />
        <h4 className="bold">2. Built-In Interpreter</h4>
        <p className="p-3 para1">
          Paradox uses the built-in REPL through which you can have access to
          the backend session and you can use its whole API as well as access
          your nodes. Any modifications like creating/renaming/deleting new
          scripts, will reactively be processed by the frontend automatically,
          so you can really do everything from the console.
        </p>
        <br />
        <h4 className="bold">3. Python3 by default</h4>
        <p className="p-3 para1">
          Paradox has almost every python inbuilt functions integrated in it,
          which you can access instantly with right click of your mouse.
        </p>
        <br />
        <h4 className="bold">4. Source Code Access</h4>
        <p className="p-3 para1">
          In Paradox you can access the Source code implementation of the nodes
          you use inside the editor. You can view and overide the source code as
          you and the result of the change change can be viewed in real time.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/source-code.jpg"}
        />
        <br />
        <h4 className="bold">5. Stylus support</h4>
        <p className="p-3 para1">
          Paradox supports stylus, you can write notes into your flows by hand
          using a stylus pen. One effective use of this feature is that it
          supports clarity and adds a whole new dimension to your visual flows.
        </p>
        <br />
        <h4 className="bold">6. Work fast with parallel computing</h4>
        <p className="p-3 para1">
          Your work feels satisfying when your work get seem less and fast.
          Through simple GUI and tools to work with for developer, Programming
          would be more easier , simpler to code, understand and build an
          incredible product.
        </p>
        <br />
        <h4 className="bold">7. Multiple Themes</h4>
        <p className="p-3 para1">
          There are many themes available in paradox, you can change them to
          which ever you like. Paradox consists of multiple themes that you can
          work with, themes like toy, tron, ghost, blender, simple, ueli, pure
          dark, colorful dark, industrial, pure light and colorful light.
        </p>
        <div classnamr="img_container">
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/toy.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/tron.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/ghost.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/blender.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/simple.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/ueli.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/pure-dark.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/colourful-dark.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/industrial.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/pure-light.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/colourful-light.jpg"}
          />
        </div>
        <br />
        <h4 className="bold">8. Multiple Modes</h4>
        <p className="p-3 para1">
          Paradox consists of two modes that you can work with, data-flow and
          exec-flow. Data-flow is the default mode. Anyone who want to use
          Paradox in any mode they like is able to do so. description data flow
          parallel computing exec-flow sequential computing.
        </p>
        <br />
        <h4 className="bold">9. Multiple Extension</h4>
        <p className="p-3 para1">
          Paradox has multiple extensions for you to use, like nodes, numpy,
          openCV, Pandas, scikit_learn. These extension provides blocks to
          complete you're overal code.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/extensions.jpg"}
        />
        <br />
        <h4 className="bold">Block</h4>
        <hr />
        <p className="p-3 para1">
          Paradox contains every Python feature in the form of blocks. These
          blocks are appear by right clicking the mouse. The name of each blocks
          can be changed and customized as you want.
        </p>
        <h5 className="bold">Inbuilt Block</h5>
        <p>1. val</p>
        <p className="p-3 para1">
          This block allows you to input the value in your program.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/val-and-result-block.jpg"}
        />
        <p>2. set var</p>
        <p className="p-3 para1">
          This block allows you to set variables, return values from other
          blocks, self-contained values.This block allows you to input the value
          in your program.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/set-var.jpg"}
        />
        <p>3. set vars passive</p>
        <p className="p-3 para1">
          This block allows you to set constant variables meaning the values do
          not change.
        </p>
        <p>4. get var</p>
        <p className="p-3 para1">
          This blocks gives the value that has been assigned to the variable.
        </p>
        <img
          className="img mb-3 w-100 h-50"
          src={process.env.PUBLIC_URL + "/assets/get-var.jpg"}
        />
        <p>5. result</p>
        <p className="p-3 para1">
          This block allows you to view the output that you get from you
          program.
        </p>
        <img
          className="img mb-3 w-100 h-50"
          src={process.env.PUBLIC_URL + "/assets/val-and-result-block.jpg"}
        />
        <p>6. Code</p>
        <p className="p-3 para1">
          This block provides you space where you can code normaly and get the
          result in the terminal at the bottom of the screen and assign the
          input and output of a variable.
        </p>
        <br />
        <h5 className="bold">System Block</h5>
        <p>1. And</p>
        <p className="p-3 para1">
          This block is Python's and operator which allows you to construct
          compound Boolean expressions that you can use to decide the course of
          action of your programs. It returns True if both statements are true.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/and.jpg"}
        />
        <p>2. IF ELSE</p>
        <p className="p-3 para1">
          This block allows you to work with an if else condition where the
          statement executes only if the test condition is True.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/if-else.jpg"}
        />
        <p>3. Button</p>
        <p className="p-3 para1">Button block is used to trigger an action.</p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/for-button.jpg"}
        />
        <p>4. Checkpoint</p>
        <p className="p-3 para1">
          Checkpoint block is used to connect multiple branch and flows withing
          the program.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/checkpoint.jpg"}
        />
        <p>5. Clock</p>
        <p className="p-3 para1">
          This block helps to set the number of iteration and the delay in
          result, it consists of a button which needs to be clicked in order to
          trigger the action.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/clock.jpg"}
        />
        <p>6. Conjugate</p>
        <p className="p-3 para1">
          Conjugate block helps the user to conjugate any matrix.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/conjugate.jpg"}
        />
        <p>7. Determinant</p>
        <p className="p-3 para1">
          Determinant helps to compute the detrminant of a matrix.{" "}
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/determinant.jpg"}
        />
        <p>8. Do while</p>
        <p className="p-3 para1">
          Determinant helps to compute the detrminant of a matrix.{" "}
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/do-while.jpg"}
        />
        <p>9. Dot Product</p>
        <p className="p-3 para1">
          The Dot Product block returns a dot product of two matrixes.{" "}
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/dot-product.jpg"}
        />
        <p>10. Eval</p>
        <p className="p-3 para1">
          This block allows you to evaluate arbitrary Python expressions from a
          string-based or compiled-code-based input.
        </p>
        <p>11. For</p>
        <p className="p-3 para1">
          This block helps create for loop for iterating over a sequence. For
          loop executes a block of code until the expression returns false.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/for-button.jpg"}
        />
        <p>12. For Each</p>
        <p className="p-3 para1">
          This block allows you to create a for each loop. It helps in creating
          control flow statement for traversing items in a collection.
        </p>
        <p>13. Herm</p>
        <p className="p-3 para1">
          This block allows you to compute the hermatian matrix.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/herm.jpg"}
        />
        <p>14. ID Matrix</p>
        <p className="p-3 para1">
          This block allows you to create an identity matrix.
        </p>
        <p>15. Imag</p>
        <p className="p-3 para1">
          This block allows you to extract the imaginary part of the matrixes
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/imag.png"}
        />
        <p>16. Inner</p>
        <p className="p-3 para1">
          This block allows you too calculate the inner product of two matrixes.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/inner.jpg"}
        />
        <p>17. Inverse</p>
        <p className="p-3 para1">
          This block helps you to find inverse of matrix
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/inverse.jpg"}
        />
        <p>18. Kron</p>
        <p className="p-3 para1">
          This block allows you to compute the product of two arrays.
        </p>
        <p>19. link IN</p>
        <p className="p-3 para1">
          This block broadcast the value, the code from this block needs to be
          pasted in the link OUT block for them to be connected and once
          connected you will be able to broadcast the value.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/link-in.jpg"}
        />
        <p>20. link OUT</p>
        <p className="p-3 para1">
          This block listens the value broadcasted by the link IN block. You
          need to copy the link from link in and paste it here for the values to
          be linked together and you will be able to listen to the bradcasted
          value.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/link-out.png"}
        />
        <p>21. log</p>
        <p className="p-3 para1">
          This block returns the value of the variable.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/log.jpg"}
        />
        <p>22. Matrix</p>
        <p className="p-3 para1">This block allows you to create matrices.</p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/matrix-mult-show-matrix.jpg"}
        />
        <p>23. Mult</p>
        <p className="p-3 para1">This block allows you to multiply matrices.</p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/matrix-mult-show-matrix.jpg"}
        />
        <p>24. nand</p>
        <p className="p-3 para1">
          The 'NAND' gate is a combination of 'AND' gate followed by 'NOT' gate.
          helps to create boolean expression
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/nand.jpg"}
        />
        <p>25. nor</p>
        <p className="p-3 para1">
          The NOR gate (negated OR) gives an output of 1 if both inputs are 0,it
          gives 0 otherwise.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/nor.jpg"}
        />
        <p>26. not</p>
        <p className="p-3 para1">
          The 'not' is a Logical operator in Python that will return True if the
          expression is False.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/not.jpg"}
        />
        <p>27. Null</p>
        <p className="p-3 para1">This block creates null matrix.</p>
        <p>28. Ones</p>
        <p className="p-3 para1">
          This block returns a new array of given shape and data type, where the
          element's value is set to 1.
        </p>
        <p>29. or</p>
        <p className="p-3 para1">
          This block helps create boolean expression that returns True if one of
          the statements is true.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/or.jpg"}
        />
        <p>30. Outer</p>
        <p className="p-3 para1">
          This block allows you to calculate the inner product of two matrices.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/outer.jpg"}
        />
        <p>31. Power</p>
        <p className="p-3 para1">
          This block allows you to assign power to matrix.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/power.png"}
        />
        <p>32. Print</p>
        <p className="p-3 para1">
          This block prints the specified message to the screen, or other
          standard output device.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/print.jpg"}
        />
        <p>33. Rand</p>
        <p className="p-3 para1">
          This block creates a matrix with random values from 0 to 1.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/rand.png"}
        />
        <p>34. Real</p>
        <p className="p-3 para1">
          This block allows you to extract the real part of the matrix.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/real.jpg"}
        />
        <p>35. show matrix</p>
        <p className="p-3 para1">This block displays the matrix.</p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/matrix-mult-show-matrix.jpg"}
        />
        <p>36. slider</p>
        <p className="p-3 para1">
          This block allows you to produce dynamic a value with the help of a
          sliding indicator.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/slider1.jpg"}
        />
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/slide2.jpg"}
        />
        <p>37. Solve</p>
        <p className="p-3 para1">
          This block allows you to solve the mathematical equations easily, it
          returns the value according to the inputs datatype.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/solve.jpg"}
        />
        <p>38. Sqrt</p>
        <p className="p-3 para1">
          This block is an inbuilt function in Python programming language that
          returns the square root of any number.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/sqrt.jpg"}
        />
        <p>39. store</p>
        <p className="p-3 para1">
          This block allows you to sequentially store all the data provided at
          input in an array. A copy of the input is provided at the output.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/store.jpg"}
        />
        <p>40. transpose</p>
        <p className="p-3 para1">
          This function permutes or reserves the dimension of the given array
          and returns the modified array.
        </p>
        <p>41. while</p>
        <p className="p-3 para1">
          This block helps set while loop where you can execute a set of
          statements as long as a condition is true.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/while.jpg"}
        />
        <p>42. xnor</p>
        <p className="p-3 para1">
          The XNOR gate (negated XOR) gives an output of 1 both inputs are same
          and 0 if both are different.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/xnor.jpg"}
        />
        <p>43. xor</p>
        <p className="p-3 para1">
          This block returns 1 if one of the bits is 1 and the other is 0 else
          returns false.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/xor.jpg"}
        />
        <br />
        <h3 className="bold">Built-in Terminal</h3>
        <p className="p-3 para1">
          Paradox has built-in terminal where you can code, enter commands and
          achieve outputs the same time. This terminal can be access by pulling
          the second tab at the bottom of your screen. The built-in terminal
          works the same as Python, here you can execute th ecode.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/for-button.jpg"}
        />
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/built-in-terminal.jpg"}
        />
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/built-in-terminal-output.jpg"}
        />
        <br />
        <h4 className="bold">Variable</h4>
        <p className="p-3 para1">
          At the right side of the Paradox UI there is a variable panel where
          you can initialize variables and also set the values.
        </p>
        <img
          className="img mb-3"
          src={process.env.PUBLIC_URL + "/assets/variables.jpg"}
        />
        <h4 className="bold">Log</h4>
        <p className="p-3 para1">
          Log is the section at the bottom of Paradox, you can get to this
          interface by pullig the bottom tab upward; this consists of global and
          error sections. Global shows the variables in the script if assigned
          and errors as the name suggests shows the error but with paradox
          errors are very unlikely to happen.
        </p>
        <h4 className="bold">Source Code</h4>
        <p className="p-3 para1">
          Paradox uses diagramatic scripting but you can also view the source
          for the script in each of the node and blocks, these source code are
          editable. You can make what ever changes you wish to make with-in the
          blocks. If the change is made in any particular block that change
          doesnot effect other blocks.You can change the functionality of the
          block by overiding the code.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/source-code.jpg"}
        />
        <h4 className="bold">Macro</h4>
        <p className="p-3 para1 ">
          A macro is a compile-time function that transforms a part of the
          program to allow functionality that cannot be expressed cleanly in
          normal library code. Paradox includes a macro option where you can
          create your custom functions/blocks. Input and output are indexed
          according to list,You can add parameters, inputs, outputs.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/macro1.jpg"}
        />
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/macro2.jpg"}
        />
        <br />
        <h4 className="bold">Extension</h4>
        <hr />
        <h4 className="bold">OpenCV</h4>
        <p className="p-3 para1">
          OpenCV (Open Source Computer Vision Library) is an open source
          computer vision and machine learning software library. OpenCV was
          built to provide a common infrastructure for computer vision
          applications and to accelerate the use of machine perception in the
          commercial products. Paradox has implemented OpenCV which can be used
          to edit and customize images as you desire.
        </p>
        <p>1. Read Image</p>
        <p className="p-3 para1">
          This block alows you too read image you want to display.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/read-display-image.png"}
        />
        <p>2. Display image</p>
        <p className="p-3 para1">
          This block allows you to display the image, you have to connect this
          block with the read image block. this block take the path of the image
          as a parameter.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/read-display-image.png"}
        />
        <p>3. Adjust brightness</p>
        <p className="p-3 para1">
          This block allows you to adjust the brightness of the image as you
          want.by giving parameters as alpha and beta
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/adjust-brightness.png"}
        />{" "}
        <p>4. Blur</p>
        <p className="p-3 para1">
          This block allows you to blur the image. size, saturation of blur
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/blur.jpg"}
        />{" "}
        <p>5. Resize</p>
        <p className="p-3 para1">
          This block allows you to change the size of the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/resize.png"}
        />{" "}
        <p>6. Scale</p>
        <p className="p-3 para1">
          This block allows you to scale the image; you can resize the details
          of the image. this block take the width and height of the image as the
          parameter.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/scale.jpg"}
        />{" "}
        <p>7. Save Image</p>
        <p className="p-3 para1">
          This block allows you to save the image that you have made all the
          changes to. you have to give file name and extenssion of the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/save-image.png"}
        />{" "}
        <p>8. Put text</p>
        <p className="p-3 para1">
          This block allows you to put your desired text over the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/put-text.jpg"}
        />{" "}
        <p>9. Filter 2D</p>
        <p className="p-3 para1">
          This block filters out the rgb value of the image. It is used to
          change the pixel intensity value of an image based on the surrounding
          pixel intensity values.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/filter-2D.png"}
        />{" "}
        <p>10. Convert colour</p>
        <p className="p-3 para1">
          This block converts the colours of the picture from one color space to
          another. The most common color spaces that is used by image editors
          are RBG and CMYK.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/convert-color.png"}
        />{" "}
        <p>11. Rectangle</p>
        <p className="p-3 para1">
          This block highlights specific size, colour, orientation of image.
        </p>
        <p>12. Threshold mask</p>
        <p className="p-3 para1">
          This block masks the image. It allows us to separate the foreground
          (i.e., the objects that we are interested in) from the background of
          the image.
        </p>
        <p>13. Circle</p>
        <p className="p-3 para1">
          This block highlights specific size, colour, orientation of image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/circle.png"}
        />{" "}
        <p>14. Threshold otsu</p>
        <p className="p-3 para1">
          This block uses Otsu algorithm to choose the optimal threshold value.
          The Otsu algorithm automatically calculates the optimal threshold to
          separate the two peaks by maximizing the variance between the two
          types of pixels .
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/threshold-otsu.png"}
        />{" "}
        <p>15. Bilatral filter</p>
        <p className="p-3 para1">
          This block allows you to remoe the noise from the image and helps
          smooth out the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/Bilateral-filter.png"}
        />{" "}
        <p>16. Threshold trunc</p>
        <p className="p-3 para1">
          When pixel intensity becomes greater than threshold value it will be
          truncated to threshold. After this the pixel values should be set to
          the value which will be same as threshold value and other values will
          be the same.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/threshold-trunc.png"}
        />{" "}
        <p>17. Haris corner detection</p>
        <p className="p-3 para1">
          The corners of an image are basically identified as the regions in
          which there are variations in large intensity of the gradient in all
          possible dimensions and directions. this block uses haris corner
          detection method to extract the corners from the input image and to
          extract features from the input image.{" "}
        </p>
        <p>18. Threshold adaptive mean</p>
        <p className="p-3 para1">
          The threshold value is the mean of the neighbourhood area minus the
          constant C.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/threshold-adaptive-mean.png"}
        />{" "}
        <p>19. Image blend</p>
        <p className="p-3 para1">
          This block helps you to combine multiple images together.
        </p>
        <p>20. Threshold to zero</p>
        <p className="p-3 para1">
          This block converts all pixels having values less than threshold, the
          pixel intensity for these is set to zero.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/threshold-to-zero.png"}
        />{" "}
        <p>21. Threshold triangle</p>
        <p className="p-3 para1">
          This block uses Triangle algorithm to choose the optimal threshold
          value. The triangle algorithm checks the shape of the histogram.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/theshold-triangle.png"}
        />{" "}
        <p>22. Threshold binary inv</p>
        <p className="p-3 para1">
          In this case the intensity of pixels will be the inverse of
          THRESH_BINARY. That is 0 when pixel value is less than threshold else
          it will be white.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/threshold-binary-inv.png"}
        />{" "}
        <p>23. Closing</p>
        <p className="p-3 para1">
          This block allows you to close small holes inside the foreground
          objects, or small black points on the object.
        </p>
        <p>24. Dilate</p>
        <p className="p-3 para1">
          This block allows you to increase the white region in the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/dilate.jpg"}
        />{" "}
        <p>25. Arrowed liked</p>
        <p className="p-3 para1">
          This block helps you add an arrow in the image. You can input where to
          place the arrow, the color of the arrow and the thickness as well.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/arrowed-liked.jpg"}
        />{" "}
        <p>26. Canny</p>
        <p className="p-3 para1">
          This block allows you to reduce noise in the image, find intensity
          gradient of the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/canny.jpg"}
        />{" "}
        <p>27. Erode</p>
        <p className="p-3 para1">
          This block allows you to erode away the boundaries of foreground
          object, the thickness or size of the foreground object decreases.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/erode.jpg"}
        />{" "}
        <p>28. Blank Image</p>
        <p className="p-3 para1">
          This block allows you to create a blank image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/blank-img.jpg"}
        />{" "}
        <p>29. Crop</p>
        <p className="p-3 para1">This block allows you to crop the image.</p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/crop.jpg"}
        />
        <p>30. Line</p>
        <p className="p-3 para1">
          This block allows you to place a line in the image. You can input
          where to place the arrow, the color of the arrow and the thickness as
          well.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/line.jpg"}
        />
        <p>31. Translate</p>
        <p className="p-3 para1">
          This block allows you to shift the image along the x and y axis. You
          can input the x axis and y axis value where you what to shift the
          image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/translate.jpg"}
        />
        <p>32. Webcam Feed</p>
        <p className="p-3 para1">
          This block allows you to access your webcam and captures the
          image/video from your webcam.
        </p>
        <p>33. Blur median</p>
        <p className="p-3 para1">
          This block allows you to blur the image using the median filter. The
          median filter run through each element of the signal (in this case the
          image) and replace each pixel with the median of its neighboring
          pixels
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/blur-median.jpg"}
        />
        <p>34. Threshold Adaptive Gaussian</p>
        <p className="p-3 para1">
          This block use the threshold method to alter the image to black and
          white, it help in finding the noide in the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={
            process.env.PUBLIC_URL + "/assets/threshold-adaptive-gaussian.jpg"
          }
        />
        <p>35. GreyScl Circle Detection</p>
        <p className="p-3 para1">
          This block allows you to detect circles in the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/greyscl-cirlce-detection.jpg"}
        />
        <p>36. Gaussian Blur</p>
        <p className="p-3 para1">
          This block uses gaussian filter and smoothes out the image.
        </p>
        <p>37. Bitwise op</p>
        <p className="p-3 para1">
          This block allows you to use the bitwise operators AND, OR, NOT and
          XOR, and extract any part of the image.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/bitwise-op.png"}
        />
        <p>38. Fourier</p>
        <p className="p-3 para1">
          This block allows you to analyze the frequency charachteristics of
          various filters.
        </p>
        <p>39. Merge</p>
        <p className="p-3 para1">
          This block allows you to merge the bgr values of the image that were
          seperated by the split color block.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/merge.jpg"}
        />
        <p>40. Rotate</p>
        <p className="p-3 para1">
          This block allows you to rotate the image. You can input thee angle
          that you want to rotate the image in.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/rotate.jpg"}
        />
        <p>41. Split Color</p>
        <p className="p-3 para1">
          This block allows you to split the image into different channels, it
          takes the rgb values of the image and seperates them.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/split-color.jpg"}
        />
        <p>41. Flip</p>
        <p className="p-3 para1">
          This block helps you to flip the image across its x-axis or y-axis.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/flip.png"}
        />
        <br />
        <h4 className="bold">Scikit learn</h4>
        <p className="p-3 para1">
          Scikit-learn is a free software machine learning library for the
          Python programming language. In general, the learning problem
          considers a set of n samples of data and then tries to predict
          properties of unknown data.
        </p>
        <p>1. Logistic Model</p>
        <p className="p-3 para1">
          Logistic regression classifies binary and multiclass values and helps
          in predicting the results. This block takes data in the form of csv
          file, ... and give predictiona as result.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/logistic-model.jpg"}
        />
        <p>2. Regression Model</p>
        <p className="p-3 para1">
          Regression model predicts numerical value prediction linear equation.
          This block takes data in the form of csv file, ... and give prediction
          as result.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/regression-model.jpg"}
        />
        <p>3. Choose Data</p>
        <p className="p-3 para1">
          This block allows you to choose data, this includes a drop down menu
          where you can find built in data that are available in Paradox as well
          as choose different that of your choice.
        </p>
        <img
          className="img w-100 h-100 mb-3"
          src={process.env.PUBLIC_URL + "/assets/regression-model.jpg"}
        />
        <p>4. Read CSV</p>
        <p className="p-3 para1">
          This block allows you to read the CSV files.
        </p>
        <p>5. Predict</p>
        <p className="p-3 para1">
          This block helps you predict the result from the data,trend model,
          input data that has been provided.
        </p>
        <p>6. Read Folder</p>
        <p className="p-3 para1">
          This block allows you to read the folder that you want to predict.
        </p>
        {/* <h3 className="bold">Code demonstration</h3>
        <p className="p-3 para1">
          Outdoor air pollution is a risk factor for several of the world’s
          leading causes of death, including stroke, heart disease, lung cancer,
          and respiratory diseases, such as asthma.
        </p>
        <br />
        <h3 className="bold">Image demonstration</h3>
        <div className="img_containers">
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/intro1.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/intro2.jpg"}
          />
          <img
            className="img w-100 h-100 mb-3"
            src={process.env.PUBLIC_URL + "/assets/intro3.jpg"}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Index;
