import { useDispatch, useSelector } from "react-redux";
import "../GetStarted/index.css";
import MenuIcon from "@mui/icons-material/Menu";
import { showSidebar, hideSidebar } from "../../../Redux/Actions/index";
import axios from "axios";

const Index = () => {
  //action dispatcher
  const dispatch = useDispatch();

  //track redux state
  const sidebarVisibility = useSelector((state) => state.sidebarVisibility);

  //close or open sidebar
  const changeSidebar = () => {
    console.log(sidebarVisibility);
    if (sidebarVisibility === "hide") {
      // //update the redux store
      dispatch(showSidebar());
    } else {
      //update the redux store
      dispatch(hideSidebar());
    }
  };

  return (
    <div className="container getStarted pt-5">
      <div className="getStartedRow px-4 pt-3">
        <MenuIcon className="btn p-0" onClick={changeSidebar} />
        <br />
        <br />
        <h1 className="bold">Getting Started</h1>
        <hr />
        <p className="p-3 para1">
          This section will cover everything you will need to know in order to
          getstarted with Paradox.
        </p>
        <h3 className="bold">Paradox Interface</h3>
        <hr />
        <p className="bold">1. Flow Themes</p>
        <p className="p-3 para1">
          Paradox boasts a number of themes that you can choose from like Tron,
          Toy, Ghost, Blender, Simple, Ueli, Pure Dark, Coorful Dark,
          Industrial, Colorful Ligth, Pure Light. The steps below will guide you
          to finding the themes.
        </p>
        <p className="p-3 para1">
          <p>Step 1. Go to the View option on the menu bar.</p>
          <p>Step 2. A list will appear. click on Flow Themes.</p>
          <p>
            Step 3. A list of themes will appear click on any one of them to
            choose a different theme.
          </p>
        </p>
        <p className="bold">2. System Theme</p>
        <p className="p-3 para1">
          Paradox has two system themes dark mode and light mode you can work on
          your prefered mode by clicking on the option dark or light on the
          start up page.
        </p>
        <p className="bold">3. Creating Script</p>
        <p className="p-3 para1">
          Paradox allows you to create scripts, you can create scripts by
          following the steps below:
        </p>
        <p className="p-3 para1">
          <p>
            Step 1. Go to the bottom left of the screen, write the name of the
            script in the text field.
          </p>
          <p>Step 2. Click on the Script button.</p>
        </p>{" "}
        <p className="bold">4. Creating Micro Script</p>
        <p className="p-3 para1">
          Paradox allows you to create micro script which is a customizable
          block that you can use in different scripts, you can create scripts by
          following the steps below:
        </p>
        <p className="p-3 para1">
          <p>
            Step 1. Go to the bottom left of the screen, write the name of the
            micro in the text field.
          </p>
          <p>Step 2. Click on the Micro button.</p>
        </p>{" "}
        <p className="bold">5. Creating Micros</p>
        <p className="p-3 para1">
          The micro script consists of two blocks input and output, you can
          follow the steps below to create your own micro script.
        </p>
        <p className="p-3 para1">
          <p>
            Step 1. Right click on the input block, a menu appears click on the
            add parameter option, two parameter data and exec will appear choose
            which ever suits your script the best.
          </p>
          <p>
            Step 2. Right Click on the output block, a menu appears, click on
            the add return option.
          </p>
          <p>
            Step 3. Choose the block you want to use for example the + block,
            and connect them to the input and output blocks.
          </p>
          <p>
            Step 4. Create a Script or go to a pre-existing script, right click
            on the screen the block menu pops up search for the micro you
            created and use it in your script.
          </p>
        </p>
        <p className="bold">6. Save Project</p>
        <p className="p-3 para1">
          Follow the steps below to save your Paradox projects:
        </p>
        <p className="p-3 para1">
          <p>Step 1. Go to the File option.</p>
          <p>Step 2. A menu will appear, click on Save Project.</p>
          <p>
            Step 3. Enter the name of of the project you want it saved as and
            click on the save button.
          </p>
        </p>
        <p className="bold">7. Enable and Disable Extension</p>
        <p className="p-3 para1">
          Paradox provides you with extensions to make your scripting experience
          smoother, follow the steps below to enable or disable them.
        </p>
        <p className="p-3 para1">
          <p>Step 1 : Go to the extension store.</p>
          <p>Step 2. Install the extension, all the blocks .</p>
          <p>Step 3. Connect the block.</p>
        </p>
        <p className="bold">8. Execution Mode</p>
        <p className="p-3 para1">
          Paradox has two modes the default Dataflow mode and execution mode.
          You can follow the steps below to work in execution mode.
        </p>
        <p className="p-3 para1">
          <p>
            Step 1. Go to the top right of the screen where you see the
            data-flow option.
          </p>
          <p>Step 2. Click on the drop down menu and click on exec-flow.</p>
        </p>
        <br />
        <p>
          Below are the guides on how to use each of the blocks that Paradox has
          to offer.
        </p>
        <h3>Basic operators</h3>
        <hr />
        <p className="bold">1. Add and Multiply</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the add or multiply block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. Enter the values in the val block that you want to add or
            multiply and press enter.
          </p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">2. AND</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the AND block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">3. NAND</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the NAND block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">4. NOT</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the NOT block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">5. OR</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the OR block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">6. Sqrt</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the Sqrt block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">7. XNOR</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the XNOR block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">8. XOR</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the XOR block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>Step 6. You will get the output in the result block.</p>
        </p>
        <p className="bold">9. FOR Loops</p>
        <p className="p-3 para1">
          <p>Step 1. Take the for block to trigger the action.</p>
          <p>Step 2. Take the For or For each block.</p>
          <p>Step 3. Take the print or val block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. Enter the values you want to print in a loop in the val or
            print block and press enter.
          </p>
          <p>
            Step 6. You will get the output in the terminal at the bottom of the
            screen.
          </p>
        </p>
        <p className="bold">10. While Loops</p>
        <p className="p-3 para1">
          <p>Step 1. Take the while block to trigger the action.</p>
          <p>Step 2. Take the While or Do While block.</p>
          <p>Step 3. Take the print or val block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. Enter the values you want to print in a loop in the val or
            print block and press enter.
          </p>
          <p>
            Step 6. You will get the output in the terminal at the bottom of the
            screen.
          </p>
        </p>
        <p className="bold">11. If else</p>
        <p className="p-3 para1">
          <p>Step 1. Take the if else block to trigger the action.</p>
          <p>Step 2. Take the If else block.</p>
          <p>Step 3. Take the print and val block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the condition you want.</p>
          <p>Step 6. You will get the output in the print or result block.</p>
        </p>
        <br />
        <h3 className="bold">Inbuilt block</h3>
        <hr />
        <p className="bold">1. Set Var</p>
        <p className="p-3 para1">
          <p>Step 1. Take the button block to trigger the action.</p>
          <p>Step 2. Take the Set var or Set var Passive block.</p>
          <p>Step 3. Take the val block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values you want to set.</p>
        </p>
        <p className="bold">2. Get var</p>
        <p className="p-3 para1">
          <p>Step 1. Take the button block to trigger the action.</p>
          <p>Step 2. Take the Get var block.</p>
          <p>Step 3. Take the print block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the variable you want to get.</p>
        </p>
        <p className="bold">3. Code</p>
        <p className="p-3 para1">
          <p>Step 1. Take the code block.</p>
          <p>Step 2. Type the code in the text box.</p>
          <p>Step 3. You will see the output in the terminal.</p>
          <p>
            Step 4. Right click on the code block, a menu will appear click on
            add input to add an input port and click on add output to add an
            output port.
          </p>
          <p>Step 5. Connect the code block with result block.</p>
          <p>Step 6. You will see the result in the result block.</p>
        </p>
        <br />
        <h3 className="bold">System Blocks</h3>
        <hr />
        <p className="bold">1. Slider</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>Step 2. Take the slider block.</p>
          <p>Step 3. Take the result block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. Enter the values in the val block and press enter.</p>
          <p>
            Step 6. Move the slider to get the desired value, the round option
            on the block gives round values.
          </p>
        </p>
        <p className="bold">2. Link in and Link out</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks for the inputs.</p>
          <p>
            Step 2. Take the link in block, click on the block, a menu will
            appear click on the copy id option.
          </p>
          <p>
            Step 3. Take the link out block, click on the block, a menu will
            appear click on the link to ID option a text box will pop-up paste
            the id that you copied from the link in block earlier here.
          </p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. You will find that the link out block broadcast the output
            from the script that it is linked to.
          </p>
        </p>
        <p className="bold">3. FOR</p>
        <p className="p-3 para1">
          <p>Step 1. Take the button block to trigger the action.</p>
          <p>Step 2. Take the For or For each block.</p>
          <p>Step 3. Take the print or val block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. Enter the values you want to print in a loop in the val or
            print block and press enter.
          </p>
          <p>
            Step 6. You will get the output in the terminal at the bottom of the
            screen.
          </p>
        </p>
        <p className="bold">4. Clock</p>
        <p className="p-3 para1">
          <p>Step 1. Take the Clock block.</p>
          <p>Step 2. Enter the values in the delay and iteration fields. </p>
          <p>Step 3. Take the print block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            {" "}
            Step 5. Press the button present in the clock block and you will get
            the output in the terminal.
          </p>
        </p>{" "}
        <p className="bold">5. Matrix</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the print, result or show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>Step 5. The matrix values will be printed in the output block.</p>
        </p>{" "}
        <p className="bold">6. Conjugate</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the conjugate block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The conjugate matrix value will be printed in the conjugate
            block.
          </p>
        </p>{" "}
        <p className="bold">7. Determinant</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the determinant block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The determinant matrix value will be printed in the output
            block.
          </p>
        </p>{" "}
        <p className="bold">8. Dot Product </p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix blocks.</p>
          <p>Step 2. Enter the values of the matrixes.</p>
          <p>Step 3. Take the dot product block and the show matrix.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The dot product of the matrices will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">9. Eval</p>
        <p className="p-3 para1">
          <p>Step 1. Take the blocks required for the script.</p>
          <p>Step 2. Take the eval block and write the code you want.</p>
          <p>
            Step 3. Connent the eval block to the output block to get the
            output.
          </p>
        </p>{" "}
        <p className="bold">10. Herm</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the herm block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The hermatian matrix value will be printed in the herm
            block.
          </p>
        </p>{" "}
        <p className="bold">11. ID Matrix</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the ID matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The identity matrix value will be printed in the ID matrix
            block.
          </p>
        </p>{" "}
        <p className="bold">12. Imag</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the Imag block, show matrix.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The imaginary values of the matrix will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">13. Inner</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix blocks.</p>
          <p>Step 2. Enter the values of the matrices.</p>
          <p>Step 3. Take the inner block, show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The inner product of the matrixes will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">14. Inverse</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix blocks.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the inverse block, show matrix block.</p>
          <p>Step 4. Connect the inverse and output blocks.</p>
          <p>
            Step 5. The inverse value of the matrix will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">15. Kron</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the kron block, show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The product value of the matrix will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">16. log</p>
        <p className="p-3 para1">
          <p>Step 1. Take the button block to trigger the action.</p>
          <p>Step 2. Take the val block enter the value you want.</p>
          <p>Step 3. Take the log block, print block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The value will be printed in the Log area at the bottom of
            the screen above the terminal.
          </p>
        </p>{" "}
        <p className="bold">17. Mult</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix blocks.</p>
          <p>Step 2. Enter the values of the matrixes.</p>
          <p>Step 3. Take the mult block, show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The product value of the matrix will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">18. Null</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the null block and connect it to the matrix block.</p>
          <p>Step 4. The null matrix will be displayed in the output block.</p>
        </p>{" "}
        <p className="bold">19. Ones</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the values of the matrix.</p>
          <p>Step 3. Take the ones block and connect it to the matrix block.</p>
          <p>Step 4. The ones matrix will be displayed in the output block.</p>
        </p>{" "}
        <p className="bold">20. Outer</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix blocks.</p>
          <p>Step 2. Enter the values of the matrixes.</p>
          <p>Step 3. Take the outer block, show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The outer product of the matrixes will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">21. Power</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block enter the value of the matrix.</p>
          <p>Step 2. Take the val block enter the value you want.</p>
          <p>Step 3 Connct the two blocks to the power block.</p>
          <p>
            Step 4. The matrix will be powered by the value you entered in the
            val block, the output will be displayed in the power block.
          </p>
        </p>{" "}
        <p className="bold">22. Rand</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks, enter the values you want.</p>
          <p>Step 2. Take the Rand block and connect it to the val blocks.</p>
          <p>
            Step 3. A random matrix will be created and displayed in the rand
            block.
          </p>
        </p>{" "}
        <p className="bold">23. Real</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block and enter a complex matrix.</p>
          <p>Step 2. Connect the matrix block with the Real block.</p>
          <p>
            Step 3. The real numbers present in the matrix will be displayed in
            the real block.
          </p>
        </p>{" "}
        <p className="bold">24. Solve</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix blocks.</p>
          <p>Step 2. Enter the values of the matrixes.</p>
          <p>Step 3. Take the solve block, show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The solved value of the matrixes will be printed in the
            output block.
          </p>
        </p>{" "}
        <p className="bold">25. Sqrt</p>
        <p className="p-3 para1">
          <p>Step 1. Take the val blocks.</p>
          <p>Step 2. Enter the values you want.</p>
          <p>Step 3. Take the Sqrt block, result.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The square root value will be printed in the output block.
          </p>
        </p>{" "}
        <p className="bold">26. Store</p>
        <p className="p-3 para1">
          <p>Step 1. Take the blocks required for the script.</p>
          <p>
            Step 2. Take the store block connect it to the value you want to
            store.
          </p>
          <p>
            Step 3. Connect the store block to any other opertion you want to
            perform.
          </p>
        </p>{" "}
        <p className="bold">27. transpose</p>
        <p className="p-3 para1">
          <p>Step 1. Take the matrix block.</p>
          <p>Step 2. Enter the value of the matrix.</p>
          <p>Step 3. Take the transpose block, show matrix block.</p>
          <p>Step 4. Connect the blocks.</p>
          <p>
            Step 5. The transpose value of the matrix will be printed in the
            output block.
          </p>
        </p>
        <br /> <h3 className="bold">OpenCV Blocks</h3>
        <hr />
        <p className="bold">1. Read Image</p>
        <p className="p-3 para1">
          <p>Step 1. Take the read image block.</p>
          <p>
            Step 2. Click on the select button and select the image you want to
            read.
          </p>
          <p>
            Step 3. You can take the val block enter the file path, connect the
            val block to the read image block and read the image.
          </p>
        </p>
        <p className="bold">2. Display image</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the display image block and choose the image you want
            to display.
          </p>
          <p>Step 2. The image will be displayed in the diaplay image block.</p>
        </p>
        <p className="bold">3. Adjust brightness</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Adjust brigthness block and connect it to the read
            image block.
          </p>
          <p>
            Step 3. Enter the apha, beta fields with the value you want or
            connect the ports to a slider and use the slider to get the desired
            results.
          </p>
          <p>
            Step 4. The image will be displayed in the adjust brightness block.
          </p>
        </p>{" "}
        <p className="bold">4. Blur</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the blur block and connect it to the read image block.
          </p>
          <p>
            Step 3. Enter the ksize field with the value you want or connect the
            ports to a slider and use the slider to get the desired results.
          </p>
          <p>Step 4. The image will be displayed in the blur block.</p>
        </p>{" "}
        <p className="bold">5. Resize</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the resize block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Enter the width and height field with the values you want.
          </p>
          <p>Step 4. The image will be displayed in the resize block.</p>
        </p>{" "}
        <p className="bold">6. Scale</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the scale block and connect it to the read image block.
          </p>
          <p>Step 3. Enter the scl value you want.</p>
          <p>Step 4. The image will be displayed in the scale block.</p>
        </p>{" "}
        <p className="bold">7. Save image</p>
        <p className="p-3 para1">
          <p>Step 1. Take the save image block.</p>
          <p>
            Step 2. Connect it to the block which displays the image you want to
            save.
          </p>
          <p>
            Step 3. Click on the choose button and choose the location you want
            to save the image in and save the image.
          </p>
        </p>{" "}
        <p className="bold">8. Put text</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Put text block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Enter the text you want to add on the image in the field
            section.
          </p>
          <p>
            Step 4. The image with the text will be displayed in the put text
            block.
          </p>
        </p>{" "}
        <p className="bold">9. Filter 2D</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Filter 2D block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Enter the depth and kernel fields with the values you want.
          </p>
          <p>Step 4. The image will be displayed in the Filter 2D block.</p>
        </p>{" "}
        <p className="bold">10. Convert colour</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the conver color block and connect it to the read image
            block.
          </p>
          <p>Step 3. The image will be displayed in the convert color block.</p>
        </p>{" "}
        <p className="bold">11. Rectangle</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the rectangle block and connect it to the read image
            block.
          </p>
          <p>Step 3. Enter the length and breadth.</p>
          <p>Step 4. The image will be displayed in the rectangle block.</p>
        </p>{" "}
        <p className="bold">12. Threshold mask</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Threshold mask block and connect it to the read
            image block.
          </p>
          <p>Step 3. Enter the Thresh, maxval values.</p>
          <p>
            Step 4. The image will be displayed in the Threshold mask block.
          </p>
        </p>{" "}
        <p className="bold">13. Circle</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the circle block and connect it to the read image
            block.
          </p>
          <p>Step 3. Enter the center, radius, color, stroke values.</p>
          <p>Step 4. The image will be displayed in the circle block.</p>
        </p>{" "}
        <p className="bold">14. Threshold otsu</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the threshold otsu block and connect it to the read
            image block.
          </p>
          <p>
            Step 3. Enter the thresh and maxval field with the values you want.
          </p>
          <p>
            Step 4. The image will be displayed in the Threshold otsu block.
          </p>
        </p>{" "}
        <p className="bold">15. Bilatral filter</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Bilateral filter block and connect it to the read
            image block.
          </p>
          <p>
            Step 3. Enter the diameter, sigma color and sigma space field with
            the values you want.
          </p>
          <p>
            Step 4. The image will be displayed in the Bilateral filter block.
          </p>
        </p>{" "}
        <p className="bold">16. Threshold trunc</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the threshold trunc block and connect it to the read
            image block.
          </p>
          <p>
            Step 3. Enter the thresh and maxval field with the values you want.
          </p>
          <p>
            Step 4. The image will be displayed in the threshold trunc block.
          </p>
        </p>{" "}
        <p className="bold">17. Harris corner detection</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Harris corner detection block and connect it to the
            read image block.
          </p>
          <p>
            Step 3. Enter the Block size, ksize and k field with the values you
            want.
          </p>
          <p>
            Step 4. The image will be displayed in the Harris corner detection
            block.
          </p>
        </p>{" "}
        <p className="bold">18. Threshold adaptive mean</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Threshold adaptive mean block and connect it to the
            read image block.
          </p>
          <p>Step 3. Enter the maxval field with the values you want.</p>
          <p>
            Step 4. The image will be displayed in the Threshold adaptive mean
            block.
          </p>
        </p>{" "}
        <p className="bold">19. Image blend</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image blocks click on the select button and
            select the images you want to read.
          </p>
          <p>
            Step 2. Take the Image blend block and connect it to the read image
            blocks .
          </p>
          <p>Step 3. The blended image will appear in the image blend block.</p>
        </p>{" "}
        <p className="bold">20. Threshold to zero</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Threshold to zero block and connect it to the read
            image block.
          </p>
          <p>Step 3. Enter the maxval field with the values you want.</p>
          <p>
            Step 4. The image will be displayed in the Threshold to zero block.
          </p>
        </p>{" "}
        <p className="bold">21. Threshold triangle</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Threshold triangle block and connect it to the read
            image block.
          </p>
          <p>
            Step 3. Enter the thresh and maxval field with the values you want.
          </p>
          <p>
            Step 4. The image will be displayed in the Threshold triangle block.
          </p>
        </p>{" "}
        <p className="bold">22. Threshold binary inv</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Threshold binary inv block and connect it to the
            read image block.
          </p>
          <p>
            Step 3. Enter the thresh and maxval field with the values you want.
          </p>
          <p>
            Step 4. The image will be displayed in the Threshold binary inv
            block.
          </p>
        </p>{" "}
        <p className="bold">23. Closing</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Closing block and connect it to the read image
            block.
          </p>
          <p>Step 3. Enter the kernel value in the closing block.</p>
          <p>Step 4. The image will be displayed in the closing block.</p>
        </p>{" "}
        <p className="bold">24. Dilate</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Dilate block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Enter the kernel and iteration value in the dilate block.
          </p>
          <p>Step 4. The image will be displayed in the dilate block.</p>
        </p>{" "}
        <p className="bold">25. Arrowed liked</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Arrowed liked block and connect it to the read
            image block.
          </p>
          <p>Step 3. Enter the pt1, pt2, color, strokeW values of the arrow.</p>
          <p>
            Step 4. The image with the arrow will be displayed in the Arrowed
            liked block.
          </p>
        </p>{" "}
        <p className="bold">26. Canny</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block and select the image you want to
            read.
          </p>
          <p>
            Step 2. Take the canny block and connect it to the read image block.
          </p>
          <p>Step 3. Enter the TS1 and TS2 values in the canny block.</p>
          <p>Step 4. The altered image will be displayed in the canny block.</p>
        </p>{" "}
        <p className="bold">27. Erode</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block and select the image you want to
            read.
          </p>
          <p>
            Step 2. Take the erode block and connect it to the read image block.
          </p>
          <p>
            Step 3. Enter the kernel and iteration values in the erode block.
          </p>
          <p>Step 4. The eroded image will be displayed in the erode block.</p>
        </p>{" "}
        <p className="bold">28. Blank img</p>
        <p className="p-3 para1">
          <p>
            Step 1.Take the read image block and select the image you want to
            read.
          </p>
          <p>
            Step 2. Take the blank image block and connec it to the read image
            block and enter dims value.
          </p>
          <p>
            Step 3. Take the display image block and connct it to the blank
            image block, the image will be displayed in the screen.
          </p>
        </p>{" "}
        <p className="bold">29. Crop</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Crop block and connect it to the read image block.
          </p>
          <p>Step 3. Enter the slice x, slice y values.</p>
          <p>Step 4. The cropped image will be displayed in the Crop block.</p>
        </p>{" "}
        <p className="bold">30. Line</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the line block and connect it to the read image block.
          </p>
          <p>Step 3. Enter the pt1, pt2, color, strokeW values.</p>
          <p>
            Step 4. The image with the line will be displayed in the line block.
          </p>
        </p>{" "}
        <p className="bold">31. Translate</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the translate block and connect it to the read image
            block.
          </p>
          <p>Step 3. Enter the x and y axis values in the translate block.</p>
          <p>
            Step 4. The edited image will be displayed in the translate block.
          </p>
        </p>{" "}
        <p className="bold">32. Webcam Feed</p>
        <p className="p-3 para1">
          <p>Step 1. Take the webcam feed block.</p>
          <p>Step 2. Your webcam feed will appear in this block.</p>
        </p>{" "}
        <p className="bold">33. Blur median</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the blur median block and connect it to the read image
            block.
          </p>
          <p>Step 3. Enter the ksize field with the value you want.</p>
          <p>Step 4. The image will be displayed in the blur median block.</p>
        </p>{" "}
        <p className="bold">34. Threshold Adaptive Gaussian</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Threshold Adaptive Gaussian block and connect it to
            the read image block.
          </p>
          <p>Step 3. Enter the maxval field with the values you want.</p>
          <p>
            Step 4. The image will be displayed in the Threshold Adaptive
            Gaussian block.
          </p>
        </p>{" "}
        <p className="bold">35. GreyScl Circle Detection</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the GreyScl Circle Detection block and connect it to
            the read image block.
          </p>
          <p>
            Step 3. Enter the dp and min_dist field with the values you want.
          </p>
          <p>
            Step 4. The image will be displayed in the GreyScl Circle Detection
            block.
          </p>
        </p>{" "}
        <p className="bold">36. Gaussian Blur</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Gaussian Blur block and connect it to the read
            image block.
          </p>
          <p>Step 3. Enter the ksize field with the values you want.</p>
          <p>Step 4. The image will be displayed in the Gaussian Blur block.</p>
        </p>{" "}
        <p className="bold">37. Bitwise op</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image blocks select the imagees that you want
            to alter.
          </p>
          <p>
            Step 2. Take the bitwise op block and connect it to the read images
            block.
          </p>
          <p>Step 3. Choose the operation you want to conduct.</p>
          <p>Step 4. The output will be displayed in the bitwise op block.</p>
        </p>{" "}
        <p className="bold">38. Merge</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Split color block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Take the display image blocks and connect them to the output
            ports of the split color block.
          </p>
          <p>
            Step 4. The images will be displayed in the display image block.
          </p>
          <p>
            Step 5. Take the images you get from above and join them to the
            merge block and the merged image will be displayed in the merge
            block.
          </p>
        </p>{" "}
        <p className="bold">39. Rotate</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Rotate block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Enter the angle and point field with the values you want.
          </p>
          <p>
            Step 4. The rotated image will be displayed in the Rotate block.
          </p>
        </p>{" "}
        <p className="bold">40. Split Color</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the Split color block and connect it to the read image
            block.
          </p>
          <p>
            Step 3. Take the display image blocks and connect them to the output
            ports of the split color block.
          </p>
          <p>
            Step 4. The images will be displayed in the display image block.
          </p>
        </p>{" "}
        <p className="bold">41. Flip</p>
        <p className="p-3 para1">
          <p>
            Step 1. Take the read image block click on the select button and
            select the image you want to read.
          </p>
          <p>
            Step 2. Take the flip block and connect it to the read image block.
          </p>
          <p>
            Step 3. You have to choose between values 0 and 1, 1 keeps the image
            as it is and 0 flips the image.
          </p>
          <p>Step 4. The flipped image will be displayed in the flip block.</p>
        </p>
        <br />
        <h3 className="bold">Scikit learn Blocks</h3>
        <hr />
        <p className="bold">1. Logistic Model</p>
        <p className="p-3 para1">
          <p>Step 1. Take the choose data, read csv block.</p>
          <p>
            Step 2. Take the logistic model block and connect it with choose
            data block and predict block.
          </p>
          <p>
            Step 3. Take the predict block and connect it with result block.
          </p>
          <p>
            Step 4. The predicted result will be displayed in the result block.
          </p>
        </p>
        <p className="bold">2. Regression Model</p>
        <p className="p-3 para1">
          <p>Step 1. Take the choose data, read csv block.</p>
          <p>
            Step 2. Take the Regression model block and connect it with choose
            data block and predict block.
          </p>
          <p>
            Step 3. Take the predict block and connect it with result block.
          </p>
          <p>
            Step 4. The predicted result will be displayed in the result block.
          </p>
        </p>
        <p className="bold">3. Predict</p>
        <p className="p-3 para1">
          <p>
            Step 1. Choose the data you want to predict with the help of choose
            data or read csv block.
          </p>
          <p>
            Step 2. Take the Predict block connect it with the regression or
            logistic model which needs to be connected with the choose data
            block.
          </p>
          <p>
            Step 3. Connect the predict block with the result block where the
            result will show up.
          </p>
        </p>
        <p className="bold">4. Read Folder</p>
        <p className="p-3 para1">
          <p>Step 1. Take the read folder block.</p>
          <p>Step 2. A menu will appear choose the folder you want to read.</p>
        </p>
        <br />
      </div>
    </div>
  );
};

export default Index;
