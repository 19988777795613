import React from "react";
import { useNavigate } from "react-router-dom";
import "../Download/index.css";

function Index() {
  //creating instance of useNavigate
  const navigate = useNavigate();

  return (
    <div className="container Download ">
      <div className="rows mt-3">
        <div className="mb-4 title">
          <h1>
            <b>Downloads</b>
          </h1>
          <img
            className="input_icon"
            src={process.env.PUBLIC_URL + "/assets/download.svg"}
            loading="lazy"
          />
        </div>
        <p className="mb-4">
          Download the latest version of our software for available
          OS. More operating systems will be added soon.
        </p>
        <div className="d-flex mb-5">
          <div className="img_container">
            <img
              src={process.env.PUBLIC_URL + "/assets/mac.png"}
              loading="lazy"
            />
          </div>
          <div className="img_container">
            <img
              src={process.env.PUBLIC_URL + "/assets/windows.svg"}
              id="win_icon"
              loading="lazy"
            />
          </div>
          <div className="img_container">
            <img
              src={process.env.PUBLIC_URL + "/assets/linux.jpg"}
              loading="lazy"
            />
          </div>
        </div>
        <button
          className="btn text-light"
          onClick={() => navigate("/Download")}
        >
          Download
        </button>
        <br />
      </div>
    </div>
  );
}

export default Index;
